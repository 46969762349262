.o-section-image-wide {
	margin-top: 60px;
	margin-bottom: 60px;
	position: relative;

	&__wrapper {
		max-width: $content-max-width;
		margin-right: auto;
		margin-left: auto;
	}

	&__image {
		display: block;
		width: 100%;
		height: auto;
		margin-bottom: 40px;
	}

	&__box {
		background: #fff;
		padding-left: 15px;
		padding-right: 15px;
	}

	// options
	&.text-left {
	}

	&.text-right {
	}

	&.background-grey {
	}

	@include mq(mobile) {
		&__box {
			padding-left: 7%;
			padding-right: 7%;
		}
	}

	@include mq(tablet) {
		&__box {
			padding-left: 20px;
			padding-right: 20px;
			max-width: 560px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include mq(desktop) {
		margin-top: calc(100px + 1vw);
		margin-bottom: calc(60px + 1vw);
		padding-left: 5%;
		padding-right: 5%;

		&__box {
			padding: 50px 100px 50px 70px;
			position: relative;
			width: 80%;
			left: 0;
			margin-right: auto;
			margin-left: 0;
			max-width: 740px;
			margin-top: -120px;
		}

		&.text-right {
			.o-section-image-wide__box {
				right: 0;
				margin-left: auto;
				margin-right: 0;
			}
		}

		// options
		&.background-grey {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: calc(80px + 1vw);
			padding-bottom: calc(60px + 1vw);
			background: $c-background;
		}
	}
}
