//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 20px !default;

//** use it within your code - it is applied to .box class and box mixin
$max-grid-width: 1200px;
