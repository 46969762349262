.overlay {
	// max-width: 100vw;
	// max-height: 100vh;
	width: 100%;
	height: 100%;
	overflow: hidden;

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	bottom: 0;

	// Needed due to iOS issue showing the iframe with full width and height
	&__scroll {
		overflow: scroll;
		height: 100%;
		width: 100%;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}
}
