.m-navigation-gallery {
	position: relative;

	&__button-next {
		top: 13px;
		left: 90px;
		height: 25px;
		width: 56px;

		.m-navigation-gallery__button-label {
			top: 7px;
			left: 5px;
			&::before {
				content: '\2192';
			}
		}
	}

	&__button-prev {
		top: 15px;
		left: 0;
		height: 25px;
		width: 30px;

		.m-navigation-gallery__button-label {
			top: 6px;
			left: 7px;
			&::before {
				content: '\2039';
			}
		}
	}

	.m-navigation-gallery__button-next,
	.m-navigation-gallery__button-prev {
		position: absolute !important;
		color: #fff;
		text-align: center;
		background: transparent;
		overflow: hidden;
		outline: 0;
		border: 0;
		vertical-align: center;
	}

	&__button-label {
		font-size: 43px;
		line-height: 0.1;
		position: absolute;
		vertical-align: center;
	}

	&__total,
	&__current {
		position: absolute;
		font-family: $ff-second;
	}

	&__current {
		top: 0;
		left: 35px;
		color: #fff;
		font-size: 48px;
		line-height: 58px;
	}

	&__total {
		left: 25px;
		top: 60px;
		color: #a2a2a2;
		font-size: 28px;
		line-height: 34px;
		width: 70px;

		&::before {
			content: '\002F ';
		}
	}
}
