.a-headline-main {
    font-family: $ff-second;
    font-size: 44px;
    line-height: 1.2;
    font-weight: $fw-regular;
    color: $fc-normal;

    &.option-color-white {
        color: #fff;
    }
}
