@import './libs/grid';
@import './libs/media-queries';

//
// grid utilities
// --------------------------------------------------

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.box-double {
    @include box-double;
}

.box {
    @include box;
}

// Row
//
// Rows contain and clear the floats of your columns.
.row-double {
    @include row-double;
}

.row {
    @include row;
}

// Columns
//
// Common styles for small and large grid columns

// @include make-grid-columns();

//
// Columns, offsets, pushes, and pulls for extra different breakpoints

// @include make-grid;
//
// @include mq(mobile) {
//     @include make-grid('m');
// }
//
// @include mq(tablet) {
//     @include make-grid('t');
// }
//
// @include mq(desktop) {
//     @include make-grid('d');
// }
//
// @include mq(wide) {
//     @include make-grid('w');
// }
