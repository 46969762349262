.a-icon-main {
    &.option-small {
        font-size: 20px;
    }

    &.option-medium {
        font-size: 30px;
    }

    &.option-large {
        font-size: 40px;
    }
}
