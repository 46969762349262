.a-button-icon {
	text-decoration: none;
	background-color: rgba(36, 36, 36, 0.3);
	display: flex;
	justify-content: center;
	border: 0;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	transition: background-color $fade-hover;
	text-decoration: none;

	& > img,
	& > div,
	& > i {
		display: inline-block;
		color: $fc-white;
	}

	&:hover {
		background-color: rgba(36, 36, 36, 0.8);
	}
}
