.m-list-small {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin-bottom: between(8px, 12px, $mq-tiny, $mq-desktop);
	}

	@include mq(desktop) {
		& > li {
			margin-bottom: 12px;
		}
	}
}
