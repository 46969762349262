.a-text-small {
    font-family: $ff-first;
    font-size: 12px;
    font-weight: $fw-regular;
    line-height: 18px;
    color: $fc-gold;

    .a-link-main {
        color: #000;
    }
}
