.m-text-section {
	font-family: $ff-first;
	font-weight: $fw-regular;
	font-size: between(16px, 21px, $mq-tiny, $mq-wide);
	color: $fc-normal;
	line-height: $lh-text;
	overflow-wrap: break-word;

	// redactor text-align
	*.text-center {
		text-align: center;
	}

	&.background-dark {
		background-color: $c-dark-grey;
		color: $fc-white;
	}

	&.background-creme {
		background-color: #f2f2f2;
		color: $fc-normal;
	}

	&.background-white {
		background-color: #fff;
		color: $fc-normal;
	}

	em,
	i {
		font-style: italic;
	}

	strong,
	b {
		font-weight: $fw-medium;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $ff-second;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		color: inherit;
		margin-top: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	& + &__headline-normal {
		font-size: between(29px, 38px, $mq-tiny, $mq-wide);
		margin-bottom: 0.4em; // between(14px, 24px, $mq-tiny, $mq-wide);
	}

	& &__headline-small {
		font-size: between(20px, 28px, $mq-tiny, $mq-wide);
		margin-bottom: 0.5em; // between(10px, 18px, $mq-tiny, $mq-wide);
	}

	p {
		margin-bottom: 1em; // between(16px, 30px, $mq-tiny, $mq-wide);

		// depending on the element following after a paragraph, margin-bottom can differ.
		// when paragraph is followed by a big headline
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + .m-text-section__headline-normal {
			margin-top: 0.9em; //between(20px, 30px, $mq-tiny, $mq-wide);
		}

		// when paragraph is followed by a middle-sized or small headline
		& + .m-text-section__headline-small {
			margin-top: 1.3em; // between(16px, 24px, $mq-tiny, $mq-wide);
		}

		// when paragraph is followed by an image (images are wrapped in figures)
		& + figure {
			margin-top: 18px;
		}
	}

	& &__label {
		font-size: between(14px, 17px, $mq-tiny, $mq-wide);
		margin-top: 0;
		margin-bottom: between(16px, 22px, $mq-tiny, $mq-wide);
	}

	& &__label-small {
		color: #6d6d6d;
		font-size: between(13px, 15px, $mq-tiny, $mq-wide);
		font-weight: $fw-light;
		margin-top: 0;
		margin-bottom: 0.8em;
	}

	& & &__highlight {
		font-size: between(18px, 27px, $mq-tiny, $mq-wide);
		font-family: $ff-first;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: $lh-highlight;
		color: $c-gold;
		margin-top: 1em;
		margin-bottom: 1.1em; // between(14px, 40px, $mq-tiny, $mq-wide);
	}

	ul,
	ol {
		// NOTE: copied from MListCheck
		@extend %m-list-check;
	}

	a:not(.a-button-text):not(.a-button-action):not(.a-link-huge) {
		@extend %a-link-main;
	}

	// img styling
	figure {
		margin: 0 0 20px;
		display: block;

		img {
		}

		figcaption {
			font-size: 14px;
			color: inherit;
			display: block;
			margin-bottom: 12px;
		}
	}

	hr {
		border-top: $line-light;
		margin: 20px 0;
	}

	& > .a-button-text,
	& > .a-button-action {
		margin-right: 14px;
	}

	@include mq(wide) {
		font-size: 19px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		& &__headline-normal {
			font-size: 38px;
		}

		& &__headline-small {
			font-size: 28px;
		}

		& &__label {
			font-size: 17px;
			margin-bottom: 22px;
		}

		& &__label-small {
			font-size: 15px;
		}

		& &__highlight {
			font-size: 27px;
		}
	}
}
