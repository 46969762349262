.m-text-box-white {
	font-family: $ff-first;
	font-weight: $fw-regular;
	font-size: between(16px, 21px, $mq-tiny, $mq-wide);
	color: $fc-normal;
	line-height: $lh-text;
	overflow-wrap: break-word;

	// redactor text-align
	*.text-center {
		text-align: center;
	}

	&.background-dark {
		background-color: $c-dark-grey;
		color: $fc-white;
	}

	&.background-creme {
		background-color: #f2f2f2;
		color: $fc-normal;
	}

	&.background-white {
		background-color: #fff;
		color: $fc-normal;
	}

	em,
	i {
		font-style: italic;
	}

	strong,
	b {
		font-weight: $fw-medium;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $ff-second;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		color: inherit;
		margin-top: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	&__headline-normal {
		font-size: between(26px, 29px, $mq-tiny, $mq-wide);
		margin-bottom: 0.4em; // between(18px, 22px, $mq-tiny, $mq-wide);
	}

	& &__headline-small {
		font-size: between(18px, 24px, $mq-tiny, $mq-wide);
		margin-bottom: 0.5em; // between(14px, 18px, $mq-tiny, $mq-wide);
	}

	p {
		margin-bottom: 1em; // between(14px, 24px, $mq-tiny, $mq-wide);

		// depending on the element following after a paragraph, margin-bottom can differ.
		// when paragraph is followed by a big headline
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + .m-text-box-white__headline-normal {
			margin-top: 0.9em; // between(22px, 28px, $mq-tiny, $mq-wide);
		}

		// when paragraph is followed by a middle-sized or small headline
		& + .m-text-box-white__headline-small {
			margin-top: 1.3em; // between(18px, 24px, $mq-tiny, $mq-wide);
		}

		// when paragraph is followed by an image (images are wrapped in figures)
		& + figure {
			margin-top: 18px;
		}
	}

	ul,
	ol {
		// NOTE: copied from MListCheck
		@extend %m-list-check;
	}

	a:not(.a-button-text):not(.a-button-action) {
		@extend %a-link-main;
	}

	// img styling
	figure {
		margin: 0 0 20px;
		display: block;

		img {
		}

		figcaption {
			font-size: 14px;
			color: inherit;
			display: block;
			margin-bottom: 12px;
		}
	}

	hr {
		border-top: $line-light;
		margin: 24px 0;
	}

	& > .a-button-text,
	& > .a-button-action {
		margin-right: 14px;
	}

	@include mq(wide) {
		font-size: 19px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		& &__headline-normal {
			font-size: 29px;
			// margin-bottom: 22px;
		}

		& &__headline-small {
			font-size: 24px;
			// margin-bottom: 18px;
		}

		p {
			// margin-bottom: 24px;

			// & + h1,
			// & + h2,
			// & + h3,
			// & + h4,
			// & + h5,
			// & + h6,
			// & + .m-text-box-white__headline-normal {
			// 	margin-top: 28px;
			// }

			// // when paragraph is followed by a middle-sized or small headline
			// & + .m-text-box-white__headline-small {
			// 	margin-top: 24px;
			// }
		}
	}
}
