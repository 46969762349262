.a-link-round {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	width: 44px;
	height: 44px;
	border: 0;
	border-radius: 50%;
	color: $ic-grey;
	opacity: 1;
	transition: opacity 0.4s $ease-out-quad;

	svg {
		fill: $ic-grey;
	}

	&:hover {
		opacity: 0.3;
	}

	&.option-color-white {
		color: #fff;
		svg {
			fill: #fff;
		}
	}

	&.option-color-black {
		color: #000;
		svg {
			fill: #000;
		}
	}
}
