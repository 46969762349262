.w-aboutus {
	.o-header-main {
		// margin-bottom: 250px;
	}

	.o-section-kitchen {
		margin-bottom: 60px;
	}

	.o-section-ambient {
	}

	.o-section-gallery {
	}

	.o-section-widget {
	}
}

@include mq(desktop) {
	.w-aboutus {
		.o-header-main {
		}

		.o-section-kitchen {
			margin-bottom: 260px;
		}

		.o-section-ambient {
			margin-bottom: 150px;
		}

		.o-section-gallery {
		}

		.o-section-widget {
		}
	}
}
