.a-link-huge {
	color: inherit;
	text-decoration: none;
	transition: color $fade-hover;
	font-size: 25px;

	&:hover {
		color: $c-gold;
	}

	&.white {
		color: #fff;
	}
}
