.a-icon-material {
	display: inline;
	color: inherit;
	font-size: $icon-size-normal;

	&.size-small {
		font-size: $icon-size-small;
	}

	&.size-normal {
		font-size: $icon-size-normal;
	}

	&.size-big {
		font-size: $icon-size-big;
	}

	// themes
	&.theme-normal {
		color: $fc-normal;
	}

	&.theme-white {
		color: $fc-white;
	}
}
