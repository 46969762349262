.m-text-legal {
	font-family: $ff-first;
	font-weight: $fw-light;
	font-size: between(16px, 21px, $mq-tiny, $mq-desktop);
	color: $fc-normal;
	line-height: $lh-text;
	overflow-wrap: break-word;

	// redactor text-align
	*.text-center {
		text-align: center;
	}

	em,
	i {
		font-style: italic;
	}

	strong,
	b {
		font-weight: $fw-medium;
	}

	h1,
	h2 {
		font-family: $ff-second;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		color: inherit;
		margin-top: 1.4em;
		margin-bottom: 0.6em;
	}

	h1 {
		font-size: between(32px, 60px, $mq-tiny, $mq-desktop);
	}

	h2 {
		font-size: between(24px, 42px, $mq-tiny, $mq-desktop);
	}

	h3,
	h4,
	h5,
	h6 {
		font-family: $ff-first;
		font-weight: $fw-light;
		line-height: $lh-normal;
		color: $fc-grey;
		text-transform: uppercase;
		font-size: between(14px, 16px, $mq-tiny, $mq-desktop);
		margin-top: 3em;
		margin-bottom: 0.4em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			margin-top: 0;
		}
	}

	p {
		margin-bottom: 1em;

		// when paragraph is followed by an image (images are wrapped in figures)
		& + figure {
			margin-top: 18px;
		}
	}

	ul,
	ol {
		font-family: inherit;
		font-style: normal;
		font-weight: inherit;
		line-height: inherit;
		font-size: inherit;
		color: inherit;
		padding-left: 26px;
		margin-top: 1.8em;
		margin-bottom: 2.2em;

		li {
			padding-left: 10px;
			margin-bottom: 16px;
		}
	}

	a:not(.a-button-text):not(.a-button-action):not(.a-link-huge) {
		@extend %a-link-main;
	}

	// img styling
	figure {
		margin: 0 0 20px;
		display: block;

		img {
		}

		figcaption {
			font-size: 14px;
			color: inherit;
			display: block;
			margin-bottom: 12px;
		}
	}

	hr {
		border-top: $line-light;
		margin: 20px 0;
	}

	& > .a-button-text,
	& > .a-button-action {
		margin-right: 14px;
	}

	@include mq(desktop) {
		font-size: 19px;

		h1 {
			font-size: 60px;
		}

		h2 {
			font-size: 42px;
		}

		h3,
		h4,
		h5,
		h6 {
			font-size: 16px;
		}
	}
}
