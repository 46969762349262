.o-section-booking {
	min-height: 60vh;
	padding: 100px 20px 80px;

	&__wrapper {
		max-width: 920px;
		margin-left: auto;
		margin-right: auto;
	}

	&__headline {
		padding: 0 15px 24px;
		font-family: $ff-second;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		font-size: between(27px, 38px, $mq-tiny, $mq-wide);
		text-align: center;
		color: $fc-normal;
		margin-bottom: 1em;
	}

	&__entry {
		padding: 0 0 40px;
	}

	@include mq(tablet) {
		padding: 120px 30px 80px;
	}

	@include mq(desktop) {
		padding: 140px 40px 80px;
	}

	@include mq(wide) {
		&__headline {
			font-size: 38px;
		}
	}
}
