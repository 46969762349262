.o-header-main {
	max-width: $page-max-width;
	margin: 80px auto;

	// options
	&.box-left {
	}
	&.box-right {
	}

	&__headline {
		padding-left: 15px;
		font-family: $ff-first;
		font-size: between(15px, 19px, $mq-tiny, $mq-desktop);
		line-height: $lh-headline;
		font-weight: $fw-regular;
		letter-spacing: 2px;
		margin-bottom: 0.6em;
		color: $fc-grey;
		text-transform: uppercase;
	}

	&__picture {
		width: 100%;
	}

	@include mq(desktop) {
		height: auto;
		padding-left: 7%;
		padding-right: 7%;

		&__headline {
			padding-left: 0;
			margin-bottom: 0.9em;
			font-size: 19px;
		}

		& > .m-box-header {
			position: relative;
			width: 80%;
			right: -5%;
			margin-left: auto;
			max-width: 1140px;
			margin-top: -120px;
		}

		&.box-left {
			& > .m-box-header {
				left: -5%;
				margin-left: 0;
				right: auto;
				margin-right: auto;
			}
		}

		&.box-right {
			& > .m-box-header {
				left: auto;
				margin-left: auto;
				right: -5%;
				margin-right: 0;
			}
		}
	}

	@include mq(wide) {
		padding-left: 120px;
		padding-right: 120px;
	}
}
