.o-overlay-newsletter {
    padding: 55px 34px;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 99999px;
    left: 0;
    background-color: #fff;
    text-align: center;
    transition: top 0.8s;
    z-index: 99999;

    &.show {
        top: 0;
        transition: top 0.8s;
    }

    .a-headline-small {
        margin-bottom: 13px;
    }

    .a-headline-sub {
        color: $fc-normal;
        margin-bottom: 5px;
    }

    .a-text-paragraph {
        margin-bottom: 50px;
    }

    .m-input-text {
        margin-bottom: 25px;
    }

    .a-link-filled {
        display: block;
        margin-bottom: 20px;
    }

    .a-link-round {
        position: absolute;
        bottom: 50px;
        left: 50%;
        margin-left: -25px;
    }
}
