.m-card-info {
	height: 260px;
	width: 100%;
	text-decoration: none;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: transform 0.6s $ease-out-cubic;

	&:hover {
		transform: scale(1.02);
	}

	// option
	&.no-hover {
		&:hover {
			transform: none;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		background: $c-dark-grey;

		&-source {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			opacity: 0.5;

			@supports (object-fit: cover) {
				width: 100%;
				height: 100%;
				max-width: auto;
				max-height: auto;
				object-fit: cover;
			}
		}
	}

	&__content {
		position: absolute;
		top: 44%;
		display: block;
		text-align: center;

		&-headline {
			border: none;
			font-size: 28px;
			font-family: $ff-second;
			font-style: normal;
			font-weight: $fw-regular;
			line-height: $lh-headline;
			color: $fc-white;
			font-size: 29px;
			margin-bottom: 12px;
		}

		&-text {
			display: none;
			font-family: $ff-first;
			font-style: normal;
			font-weight: $fw-regular;
			line-height: $lh-text;
			font-size: between(16px, 21px, $mq-tiny, $mq-desktop);
			color: $fc-normal;
		}
	}

	@include mq(tablet) {
		height: 300px;
	}

	@include mq(desktop) {
		width: 31%;
		height: auto;
		margin-top: 0;
		justify-content: flex-start;
		align-items: flex-start;

		&:hover {
			transform: translateY(-20px) scale(1.02);
			z-index: 1;
		}

		&__image {
			width: 100%;
			height: 580px;

			&-source {
				opacity: 1;
			}
		}

		&__content {
			width: 90%;
			padding: 44px 36px 40px 0px;
			position: relative;
			background-color: #fff;
			text-align: left;
			margin-top: -100px;
			left: 0px;

			&-headline {
				color: #000;
			}

			&-text {
				display: block;
				font-size: 21px;
			}
		}
	}
}
