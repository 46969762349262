.o-section-ambient {
    padding-bottom: 40px;
    background-color: $c-dark-grey;

    .o-section-main__image-container {
        margin-bottom: 30px;
    }

    .a-headline-section {
        margin-bottom: 20px;
    }
}

@include mq(desktop) {
    .o-section-ambient {
        padding-bottom: 184px;
        background-color: #fff;

        .o-section-main__image-container {
            margin-bottom: 30px;
        }

        .a-headline-section {
            margin-bottom: 20px;
        }

        .o-section-main__text {
            bottom: -340px;
        }
    }
}
@include mq(wide) {
    .o-section-ambient {
        .o-section-main__text {
            bottom: -300px;
        }
    }
}
