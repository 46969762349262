.a-link-underline {
    display: inline-block;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1.2;
    text-align: center;
    padding: 4px 17px;
    color: #000;

    text-transform: uppercase;

    &.active {
        border-bottom: 1px solid #000;
    }
}
