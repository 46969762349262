.o-section-present {
    .o-section-main__text {
        padding-top: 50px;
        padding-bottom: 50px;

        .a-headline-section {
            margin-bottom: 20px;
        }
    }
}

@include mq(desktop) {
    .o-section-present {
        padding-bottom: 100px;

        .o-section-main__text {
            padding-top: 50px;
            padding-bottom: 50px;

            .a-headline-section {
                margin-bottom: 20px;
            }
        }
    }
}

@include mq(wide) {
    .o-section-present {
        padding-bottom: 200px;
    }
}
