/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 *
 * see: https://css-tricks.com/between-the-lines/
 */

@function between($from, $to, $fromWidth, $toWidth) {
	$slope: ($to - $from) / ($toWidth - $fromWidth);
	$base: $from - $slope * $fromWidth;

	@return calc(#{$base} + #{100vw * $slope});
}
