.o-header-event {
	background-color: #fff;
}

.o-header-event__inner-background {
	height: 100%;
	background-size: contain;

	&.background-particles {
		background-image: url('../../assets/img/_header/OHeaderEvent/event-particles.svg');
	}
}

.o-header-event__imagecontainer {
	padding-top: 130px;
	min-height: 200px;
	overflow: hidden;

	.o-header-event__img {
		width: 100%;
		height: auto;
	}
}

.o-header-event__details {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	margin-bottom: 20px;
	line-height: 33px;
}

.o-header-event__text {
	padding: 45px 5% 100px;
	background-color: $c-dark-grey;

	.a-link-filled {
		margin-top: 50px;
	}

	.a-headline-section {
		margin-bottom: 20px;
		color: #fff;
	}

	.a-text-paragraph {
		color: #fff;
	}
}

@include mq(desktop) {
	.o-header-event {
		height: auto;
		padding-top: 180px;
		padding-left: 6.74%;
		padding-right: 6.74%;
	}

	.o-header-event__inner-background {
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		background-size: auto;
		background-repeat: repeat;
	}

	.o-header-event__imagecontainer {
		display: block;
		height: auto;
		background-color: transparent;

		padding-top: 140px;
	}
	.o-header-event__text {
		background-color: $c-dark-grey;
		width: 80%;
		max-width: 866px;
		position: relative;
		top: -150px;
		left: 25%;
		padding: 60px 50px;

		.a-text-paragraph {
			font-size: 26px;
			line-height: 36px;
		}

		.a-headline-section {
			color: #fff;
			margin-bottom: 16px;
		}

		.o-header-event__details {
			font-size: 22px;
		}
	}
}

@include mq(wide) {
	.o-header-event__text {
		width: 70%;
		max-width: 866px;
		padding: 90px 80px;
		top: -170px;
		left: 35%;

		.o-header-event__details {
			position: absolute;
			width: 50%;
			left: -50%;
			bottom: 0;
			background-color: #fff;
			border: 0;
			color: $fc-normal;
			margin-bottom: 0;
		}
	}
}
