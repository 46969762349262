.o-header-special {
	height: 100vh;
}

.o-header-special__inner-background {
	background: url('../../assets/img/_header/OHeaderSpecial/home-header.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 30%;
}

.o-header-special__content {
	padding: 0 5%;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	background-color: rgba(0, 0, 0, 0.5);

	.a-headline-main {
		margin-bottom: 20px;
	}
}

@include mq(desktop) {
	.o-header-special {
		.a-headline-main {
			font-size: 88px;
			line-height: 1;
			margin-bottom: 50px;
		}
	}

	.o-header-special__inner-background {
	}

	.o-header-special__content {
		padding-left: 6.74%;
		padding-right: 6.74%;
	}

	.o-header-special__background {
		max-width: 1200px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
