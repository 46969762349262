.o-slide-image {
	&:focus {
		outline: none;
	}

	&__img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;

		@supports (object-fit: cover) {
			width: 100%;
			height: 100%;
			max-width: auto;
			max-height: auto;
			object-fit: cover;
		}
	}

	@include mq(desktop) {
		text-align: center;
		// overflow: hidden;

		&__img {
			width: 100%;
		}
	}
}
