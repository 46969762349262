/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden {
    display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * helper to change display values based on breakpoints
 */
.none {
    display: none;
}
.auto {
    display: auto;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}

@include mq(mobile) {
    .none-m {
        display: none;
    }
    .auto-m {
        display: auto;
    }
    .block-m {
        display: block;
    }
    .inline-block-m {
        display: inline-block;
    }
    .inline-m {
        display: inline;
    }
}

@include mq(tablet) {
    .none-t {
        display: none;
    }
    .auto-t {
        display: auto;
    }
    .block-t {
        display: block;
    }
    .inline-block-t {
        display: inline-block;
    }
    .inline-t {
        display: inline;
    }
}

@include mq(desktop) {
    .none-d {
        display: none;
    }
    .auto-d {
        display: auto;
    }
    .block-d {
        display: block;
    }
    .inline-block-d {
        display: inline-block;
    }
    .inline-d {
        display: inline;
    }
}

@include mq(wide) {
    .none-w {
        display: none;
    }
    .auto-w {
        display: auto;
    }
    .block-w {
        display: block;
    }
    .inline-block-w {
        display: inline-block;
    }
    .inline-w {
        display: inline;
    }
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}
