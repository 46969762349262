@import './clearfix';
@import './media-queries';
@import './grid/grid-framework';

// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
@mixin box($gutter: $grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    max-width: $max-grid-width;
    @include clearfix;
}

@mixin box-double($gutter: $grid-gutter-width) {
    // box with full gutter on sides
    margin-right: auto;
    margin-left: auto;
    padding-left: $gutter;
    padding-right: $gutter;
    max-width: $max-grid-width;
    @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin row($gutter: $grid-gutter-width) {
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
    @include clearfix;
}

@mixin row-double($gutter: $grid-gutter-width) {
    margin-left: ($gutter);
    margin-right: ($gutter);
    @include clearfix;
}

// Generate the extra small columns
@mixin col($columns, $gutter: $grid-gutter-width) {
    position: relative;
    float: left;
    min-height: 1px;
    @include _col($columns, $gutter);
}
@mixin _col($columns, $gutter: $grid-gutter-width) {
    width: percentage(($columns / $grid-columns));
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
}
@mixin col-wider($gutter: $grid-gutter-width) {
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
}
@mixin push($columns) {
    left: percentage(($columns / $grid-columns));
}
@mixin pull($columns) {
    right: percentage(($columns / $grid-columns));
}
@mixin pre($columns) {
    margin-left: percentage(($columns / $grid-columns));
}
@mixin next($columns) {
    margin-right: percentage(($columns / $grid-columns));
}
@mixin squish($columns) {
    margin-left: percentage(($columns / $grid-columns));
    margin-right: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin col-tab($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);

    @include mq(tablet) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin push-tab($columns) {
    @include mq(tablet) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin pull-tab($columns) {
    @include mq(tablet) {
        right: percentage(($columns / $grid-columns));
    }
}
@mixin squish-tab($columns) {
    @include mq(tablet) {
        margin-left: percentage(($columns / $grid-columns));
        margin-right: percentage(($columns / $grid-columns));
    }
}

// Generate the medium columns
@mixin col-desk($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);

    @include mq(desktop) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin push-desk($columns) {
    @include mq(desktop) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin pull-desk($columns) {
    @include mq(desktop) {
        right: percentage(($columns / $grid-columns));
    }
}
@mixin squish-desk($columns) {
    @include mq(desktop) {
        margin-left: percentage(($columns / $grid-columns));
        margin-right: percentage(($columns / $grid-columns));
    }
}

// Generate the large columns
@mixin col-wide($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);

    @include mq(wide) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin push-wide($columns) {
    @include mq(wide) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin pull-wide($columns) {
    @include mq(wide) {
        right: percentage(($columns / $grid-columns));
    }
}
@mixin pre-wide($columns) {
    @include mq(wide) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin squish-wide($columns) {
    @include mq(wide) {
        margin-left: percentage(($columns / $grid-columns));
        margin-right: percentage(($columns / $grid-columns));
    }
}
