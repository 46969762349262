.o-slide-event {
	width: 250px;
	height: 350px;
	position: relative;
	padding: 20px;
	overflow: hidden;
	display: block;
	text-decoration: none;
	background-color: $c-dark-grey;
	border: 1px solid #eee;
	transition: transform 0.6s $ease-out-cubic;

	&:hover {
		transform: translateY(-10px) scale(1.01);
	}

	&:focus {
		outline: none;
	}

	&__background {
		position: absolute;
		width: auto;
		height: 100%;
		left: 0;
		top: 0;
	}

	&__content {
		position: relative;
		color: #fff;
		height: 100%;

		.a-button-action {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	&__date {
		font-family: $ff-second;
		font-size: 18px;
		color: $fc-gold;
		margin-bottom: 10px;
	}

	&__line {
		border-top: 3px solid #fff;
	}

	&__title {
		font-family: $ff-second;
		font-size: 24px;
		color: #fff;
		font-weight: $fw-regular;
	}
}
