.a-headline-sub {
	color: #fff;
	font-size: 24px;
	font-family: $ff-second;
	font-weight: $fw-regular;
	line-height: 1.2;
}

// @include mq(desktop) {
//     .a-headline-sub {
//         font-size: 36px;
//     }
// }
