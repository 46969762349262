.o-section-voucher {
	min-height: 60vh;
	padding: 100px 20px 80px;

	@include mq(tablet) {
		padding: 120px 20px 80px;
	}

	@include mq(desktop) {
		padding: 140px 20px 80px;
	}
}
