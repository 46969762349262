%a-button-action,
.a-button-action {
	font-family: $ff-first;
	font-style: normal;
	font-weight: $fw-regular;
	line-height: 1;
	font-size: rem(19px);
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	color: $fc-white;
	display: inline-block;
	outline: none;
	border: 0;
	text-align: center;
	background: $c-gold;
	border-radius: 0;
	vertical-align: middle;
	padding: 14px 20px;
	transition: background 0.6s $ease-out-quart;

	&:hover {
		background: $c-gold-dark;
	}

	@include mq(desktop) {
		padding: 15px 20px;
	}
}
