.m-control-menu {
	height: 48px;
	border: 0;
	background: transparent;
	box-shadow: none;
	text-decoration: none;
	outline: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		transition: background-color $fade-hover;
		text-decoration: none;
		background-color: rgba(36, 36, 36, 0.3);

		& > .a-icon-material {
			color: $fc-white;
		}
	}

	&__label {
		display: none;
		padding-left: 16px;
		font-family: $ff-first;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: $lh-normal;
		font-size: 19px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $fc-white;
		vertical-align: 19px;
	}

	&:hover {
		.m-control-menu__button {
			background-color: rgba(36, 36, 36, 0.8);
		}
	}

	@include mq(tablet) {
		&__label {
			display: inline-block;
		}
	}
}
