.o-slideshow-gallery {
	&__container {
		height: 100%;
	}

	.o-slide-image {
		height: 60vh;
	}

	@include mq(tablet) {
		&__container {
			.slick-slide {
			}
		}
	}

	@include mq(desktop) {
		// width: 100%;
		// height: 100%;
		position: relative;

		.m-navigation-gallery {
			display: block;
			position: absolute;
			right: -50px;
		}

		&__container {
			.slick-list,
			.slick-track,
			.slick-slide div,
			.o-slide-image {
				height: 100%;
			}
		}
	}
}
