.o-section-links {
	background-color: $c-super-dark;
	padding-top: 30px;
	padding-bottom: 1px;

	&__wrapper {
		max-width: $content-max-width;
		margin: 0 auto;

		& > .m-view-links {
			margin: 0 auto 8px;
		}
	}

	@include mq(tablet) {
		padding-top: 100px;
		padding-bottom: 80px;

		&__wrapper {
			& > .m-view-links {
				margin-bottom: 60px;
			}
		}
	}

	@include mq(desktop) {
		padding-top: 120px;
		padding-bottom: 160px;

		&__wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: flex-start;

			& > .m-view-links {
				width: 42%;
				margin-bottom: 0;
			}
		}
	}
}
