.o-section-cook {
    .o-section-main__text {
        padding: 50px 0;
        text-align: center;
    }
}

@include mq(tablet) {
    .o-section-cook {
        padding-bottom: 50px;

        .o-section-main__row {
            align-items: center;
        }
    }
}

@include mq(desktop) {
    .o-section-cook {
        padding-top: 150px;
        padding-bottom: 150px;

        .o-section-main__row {
            align-items: center;
        }

        .o-section-main__text {
            text-align: left;
        }
    }
}
