.m-comment-main {
	padding: 10px 7% 20px;

	&__wrapper {
		text-align: center;
		max-width: 460px;
		margin-left: auto;
		margin-right: auto;
	}

	&__head {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
	}

	&__stars {
		display: flex;
		justify-content: flex-start;
	}

	&__star {
		width: 20px;
		height: 20px;
		margin-right: 5px;
		background-size: contain;

		&.full {
			background-image: url('../../assets/img/_comments/MCommentMain/star_full.svg');
		}

		&.half {
			background-image: url('../../assets/img/_comments/MCommentMain/star_half.svg');
		}

		&.empty {
			background-image: url('../../assets/img/_comments/MCommentMain/star_empty.svg');
		}
	}

	&__headline-stars,
	&__headline-foot {
		color: #545454;
		font-weight: $fw-light;
		font-size: 13px;
		font-family: $ff-first;

		a {
			color: inherit;
			text-decoration: inherit;
			font-family: inherit;
		}
	}

	&__headline-stars {
		display: none;
		margin-left: 16px;
	}

	&__headline-foot {
		margin-top: 26px;
	}

	&__text {
		font-family: $ff-first;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: $lh-text;
		font-size: between(18px, 21px, $mq-tiny, $mq-desktop);
		color: $fc-normal;

		&:before {
			content: '„';
			margin-right: -3px;
		}
		&:after {
			content: '“';
			margin-left: -3px;
		}
	}

	@include mq(desktop) {
		width: 470px;
		// give it a look to come from the right side ...
		padding: 10px 10px 20px 80px;

		&__wrapper {
			text-align: left;
			max-width: none;
		}

		&__head {
			margin-bottom: 16px;
			justify-content: flex-start;
		}

		&__star {
			width: 18px;
			height: 18px;
		}

		&__headline-stars {
			display: inline-block;
			padding-top: 4px;
		}

		&__headline-foot {
			display: none;
		}

		&__text {
			font-size: 21px;
		}
	}
}
