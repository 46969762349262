.a-text-paragraph {
	font-family: $ff-first;
	font-weight: $fw-light;
	font-size: 19px;
	color: $fc-normal;
	line-height: $lh-text;

	&.option-color-white {
		color: #fff;
	}

	&.option-semibold {
		font-weight: $fw-semibold;
	}

	strong,
	a {
		font-weight: $fw-medium;
	}
}

@include mq(desktop) {
	.a-text-paragraph {
		font-size: 21px;
	}
}
