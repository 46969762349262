.o-section-gallery {
	padding-top: 0px;
	padding-bottom: 80px;

	.o-section-main__text {
		text-align: center;
	}

	&__gallery {
		.m-navigation-gallery {
			display: none;
		}

		.o-slideshow-gallery {
			margin-bottom: 20px;

			&.next,
			&.prev {
				display: none;
			}
		}

		// FIX: We show the dots only on mobile to desktop
		.slick-dots {
			margin-bottom: -10px;
			position: relative;
			top: 4px;
		}
	}

	@include mq(desktop) {
		padding-top: 165px;
		padding-bottom: 148px;
		position: relative;
		background-color: $c-dark-grey;

		.o-section-main__text {
			margin-left: 23%;
			text-align: left;
		}

		&__gallery {
			height: 500px;
			position: relative;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			.o-slideshow-gallery {
				.slick-list,
				.slick-track {
					outline: none !important;
				}

				&.prev {
					margin-right: 20px;
					margin-top: -5%;
					overflow: hidden;
					height: 90%;
					width: 21%;
					display: inline-block;
				}

				&.current {
					display: inline-block;
					width: 50%;
					height: 80%;
					margin-top: 5%;
					overflow: hidden;
				}

				&.next {
					margin-left: 20px;
					overflow: hidden;
					height: 40%;
					width: 25%;
					display: inline-block;
				}
			}

			.slick-dots {
				display: none;
			}

			.m-navigation-gallery {
				display: block;
				position: absolute;
				bottom: 115px;
				right: 17%;
			}
		}
	}
}
