.a-link-outline {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 36px;
    text-align: center;
    padding: 6px 25px;
    color: $fc-normal;
    border: 1px solid $fc-normal;
    text-transform: uppercase;

    &:hover {
        color: #fff;
        border-color: #fff;
        background-color: #191919;
    }

    &.option-color-white {
        color: #fff;
        border-color: #fff;

        &:hover {
            color: $fc-normal;
            border-color: $fc-normal;
            background-color: #fff;
        }
    }
}
