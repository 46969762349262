// Butler

@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-ultra-light.woff') format('woff'),
        url('./../fonts/Butler/butler-ultra-light.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-light.woff') format('woff'),
        url('./../fonts/Butler/butler-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-regular.woff') format('woff'),
        url('./../fonts/Butler/butler-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-medium.woff') format('woff'),
        url('./../fonts/Butler/butler-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-bold.woff') format('woff'),
        url('./../fonts/Butler/butler-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-extrabold.woff') format('woff'),
        url('./../fonts/Butler/butler-extrabold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'butler';
    src: url('./../fonts/Butler/butler-black.woff') format('woff'),
        url('./../fonts/Butler/butler-black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

//Neuzeit Grotesk
@font-face {
    font-family: 'NeuzeitGro';
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-light.eot')
        format('eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-light.woff')
            format('woff'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-light.woff2')
            format('woff2'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-regular.eot')
        format('eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-regular.woff')
            format('woff'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-regular.woff2')
            format('woff2'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-regular.ttf')
            format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-bold.eot') format('eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-bold.woff')
            format('woff'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-bold.woff2')
            format('woff2'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black.eot')
        format('eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black.woff')
            format('woff'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black.woff2')
            format('woff2'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-condensed.eot')
        format('eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-condensed.woff')
            format('woff'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-condensed.woff2')
            format('woff2'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-condensed.ttf')
            format('ttf');
    font-weight: 900;
    font-style: normal;
    font-stretch: condensed;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-extra-condensed.eot')
        format('eot'); /* IE9+ Compat Modes */
    src: url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-extra-condensed.woff')
            format('woff'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-extra-condensed.woff2')
            format('woff2'),
        url('./../fonts/NeuzeitGrotesk/neuzeit-grotesk-black-extra-condensed.ttf')
            format('ttf');
    font-weight: 900;
    font-style: normal;
    font-stretch: extra-condensed;
}
