.o-section-action-gold {
	margin-top: 60px;
	margin-bottom: 60px;
	color: $fc-white;
	font-family: $ff-first;
	font-weight: $fw-regular;
	text-align: center;

	&__box {
		position: relative;
		overflow: hidden;
		max-width: $content-max-width;
		margin-left: auto;
		margin-right: auto;
		background: $c-gold;
		padding-top: between(80px, 100px, $mq-tiny, $mq-super);
		padding-bottom: between(40px, 50px, $mq-tiny, $mq-super);
		padding-left: 7%;
		padding-right: 7%;

		&-headline {
			font-family: $ff-second;
			font-weight: $fw-regular;
			font-size: between(32px, 60px, $mq-tiny, $mq-super);
			margin-bottom: 0.1em;
		}

		&-text {
			font-size: between(17px, 27px, $mq-tiny, $mq-super);
			margin-bottom: between(40px, 50px, $mq-tiny, $mq-super);
		}

		&-logo {
			display: none;
		}

		& > .a-button-text {
		}
	}

	@include mq(desktop) {
		margin-top: calc(80px + 1vw);
		margin-bottom: calc(80px + 1vw);
		margin-left: 80px;
		margin-right: 80px;

		&__box {
			padding-left: 15%;
			padding-right: 15%;

			&-logo {
				position: absolute;
				display: inline-block;
				right: 5%;
				bottom: -63px;
			}
		}
	}

	@include mq(super) {
		&__box {
			padding-top: 100px;
			padding-bottom: 50px;

			&-headline {
				font-size: 60px;
			}

			&-text {
				font-size: 27px;
				margin-bottom: 50px;
			}
		}
	}
}
