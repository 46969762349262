%m-list-check,
.m-list-check {
	font-family: $ff-first;
	font-style: normal;
	font-weight: $fw-regular;
	line-height: $lh-normal;
	font-size: inherit;
	color: inherit;
	padding-left: 26px;
	margin-top: 2em;
	margin-bottom: 2.4em;
	list-style-image: url('./../../assets//img/_lists/MListCheck/list-icon-check.svg');

	li {
		padding-left: 10px;
		margin-bottom: 16px;
	}
}
