.o-section-comments {
	background-color: $c-background;
	padding: 60px 0;

	&__wrapper {
		// max-width: 460px;
		// margin-left: auto;
		// margin-right: auto;
	}

	&__headline {
		padding: 0 15px;
		font-family: $ff-second;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		font-size: between(27px, 38px, $mq-tiny, $mq-wide);
		text-align: center;
		color: $fc-normal;
		margin-bottom: 0.8em;
	}

	&__slider {
		position: relative;

		&-comments {
			width: 100%;
		}

		& > .m-pagination-bullets {
			margin-top: 30px;
		}

		&-next {
			display: none;
			position: absolute;
			border: 0;
			border-radius: 0;
			background: $c-background;
			color: $ic-grey;
			width: 63px;
			height: 56px;
			right: 0;
			top: 64px;
			z-index: 2;
			outline: none;
		}
	}

	&__reviews {
		padding: 0 20px;
		margin-top: 60px;
		max-width: $content-max-width;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;

		&-item {
			padding: 15px;
			max-width: 280px;

			& > * {
				max-width: 100% !important;
			}
		}
	}

	@include mq(tablet) {
		padding: 80px 0;

		&__reviews {
			padding: 0 40px;
			margin-top: 80px;
		}
	}

	@include mq(desktop) {
		padding: 100px 0;
		background: transparent;

		&__slider {
			& > .m-pagination-bullets {
				margin-top: 40px;
				display: none;
			}

			&-next {
				display: block;
			}
		}

		&__reviews {
			padding: 0 100px;
			margin-top: 80px;
		}

		&__headline {
			margin-bottom: 1.8em;
		}
	}

	@include mq(wide) {
		padding: 100px 0 120px;

		&__headline {
			font-size: 38px;
		}
	}
}
