.a-button-main {
	border: 0;
	outline: none;
	display: inline-block;
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 36px;
	text-align: center;
	padding: 6px 25px;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	background-color: transparent;
	border-radius: 0;

	&.option-outline-white {
		border: 1px solid #fff;
	}

	&.option-background-white {
		background-color: #fff;
		color: #000;
	}
}
