.o-menu-main {
	height: 100vh;
	width: 100vw;
	opacity: 0.3;
	position: fixed;
	background-color: #fff;
	z-index: $z-menu;
	overflow-y: scroll;
	top: 0;
	left: -999999999999999px;
	pointer-events: none;
	transition: opacity 0.4s $ease-out-cubic, transform 0.4s $ease-out-quart;
	transform: translateY(30px) scale(0.97);

	& > .o-bar-menu {
		z-index: $z-menu + 1;
		position: fixed;
		top: 0;
		left: -999999999999999px;
		width: 100vw;
	}

	&.js-show {
		top: 0;
		left: 0;
		opacity: 1;
		transform: translateY(0) scale(1);
		pointer-events: all;

		& > .o-bar-menu {
			top: 0;
			left: 0;
		}
	}

	&.js-hide {
		opacity: 0;
		// keep time in sync with javascript!!
		transition: opacity 0.4s $ease-out-cubic;
		pointer-events: none;
	}

	&__content {
		max-width: $content-max-width;
		margin: 0 auto;
		padding: 120px 20px 80px;
	}

	&__column-right,
	&__column-left {
		text-align: center;

		.m-list-huge {
			margin-bottom: 30px;
		}

		.m-list-small {
			margin-bottom: 30px;
		}
	}

	&__column-right {
		display: none;

		.m-link-highlight {
			margin-bottom: 18px;
		}
	}

	&__social {
		display: flex;
		justify-content: center;

		.a-link-round {
			margin: 0 4px;
		}
	}

	@include mq(desktop) {
		&__content {
			padding-left: 6.74%;
			padding-right: 6.74%;
			display: flex;
			flex-direction: row;
		}

		&__column-right,
		&__column-left {
			width: 50%;
			text-align: left;

			.m-list-huge {
				display: inline-block;
			}
		}

		&__column-right {
			display: block;
		}

		&__social {
			justify-content: flex-start;
			// fix to align icons optically with the text
			margin-left: -16px;
		}
	}

	@include mq(wide) {
	}
}
