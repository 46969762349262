.o-overlay-gallery {
	padding: 35px 0;
	height: 100vh;
	width: 100%;
	position: fixed;
	overflow-y: scroll;
	top: 99999px;
	left: 0;
	background-color: #fff;
	text-align: center;
	transition: top 0.8s;
	z-index: 99999;

	&.show {
		top: 0;
		transition: top 0.8s;
	}

	&.option-background-dark-grey {
		background-color: $c-dark-grey;
		color: #fff;
	}

	.a-link-round {
		position: absolute;
		bottom: 34px;
		left: 50%;
		margin-left: -25px;
	}

	.o-slideshow-gallery {
		.slick-slide {
			height: 400px;
		}
	}
}

@include mq(tablet) {
	.o-overlay-gallery {
		.o-slideshow-gallery {
			.slick-slide {
				height: 700px;
			}
		}
	}
}
