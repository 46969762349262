.o-section-subscribe {
    height: 100vh;
    text-align: center;

    .a-headline-sub {
        color: $fc-normal;
        margin-bottom: 5px;
    }

    .o-section-main__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 640px;
    }

    .a-headline-small {
        margin-bottom: 13px;
    }

    .a-text-paragraph {
        margin-bottom: 50px;
    }

    .m-input-text {
        margin-bottom: 25px;
    }

    .a-link-filled {
        display: block;
        margin-bottom: 20px;
    }
}
