.o-overlay-info {
	position: fixed;
	z-index: $z-overlay;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	// keep time in sync with js!!
	transition: opacity 0.4s $ease-out-cubic, transform 0.4s $ease-out-quart;
	transform: translateY(30px) scale(0.97);
	pointer-events: none;
	background: $c-background-overlay;
	left: -999999999px;

	&.js-show {
		pointer-events: all;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		transform: translateY(0) scale(1);
	}

	// moves the overlay outside of the view
	&.js-hide {
		transition: opacity 0.4s $ease-out-quart;
		opacity: 0;
	}

	& > .a-button-close {
		position: absolute;
		bottom: 15px;
		left: calc(50% - 24px);
	}

	&__iframe {
		width: 100%;
		height: 100%;
		border: 0;
		padding: 0;
		margin: 0;
		background: transparent;
		opacity: 0;
		transition: opacity 0.3s $ease-out-cubic;

		// when iframe is loading
		&.js-iframe-is-loading {
			opacity: 0;
		}

		// when iframe has been loaded
		&.js-iframe-loaded {
			opacity: 1;
		}
	}

	@include mq(desktop) {
		& > .a-button-close {
			bottom: auto;
			left: auto;
			top: 50px;
			right: 40px;
		}
	}
}
