.a-link-menu-primary {
	color: #000;
	display: inline-block;
	position: relative;
	letter-spacing: 2px;
	font-family: $ff-first;
	font-size: between(18px, 24px, $mq-tiny, $mq-desktop);
	transition: color $fade-hover;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		color: $c-gold;
	}

	& > .a-icon-material {
		position: absolute;
		left: between(-22px, -28px, $mq-tiny, $mq-desktop);
		top: between(4px, 5px, $mq-tiny, $mq-desktop);
		font-size: between(14px, 16px, $mq-tiny, $mq-desktop);
		color: inherit;
	}

	@include mq(desktop) {
		font-size: 24px;

		& > .a-icon-material {
			left: -28px;
			top: 5px;
			font-size: 16px;
		}
	}
}
