.o-overlay-video {
    padding: 35px 0;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 99999px;
    left: 0;
    background-color: #fff;
    text-align: center;
    transition: top 0.8s;
    z-index: 99999;

    &.show {
        top: 0;
        transition: top 0.8s;
    }

    &.option-background-dark-grey {
        background-color: $c-dark-grey;
        color: #fff;
    }

    .a-link-round {
        position: absolute;
        bottom: 34px;
        left: 50%;
        margin-left: -25px;
    }
}

.o-overlay-video__container {
    position: relative;
    padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
    padding-top: 0px;
    height: 0;
    overflow: hidden;
    .o-overlay-video__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@include mq(tablet) {
}
