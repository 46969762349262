%a-button-text,
.a-button-text {
	font-family: $ff-first;
	font-style: normal;
	font-weight: $fw-regular;
	line-height: 1;
	font-size: rem(19px);
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	color: $c-gold;
	display: inline-block;
	outline: none;
	border: 0;
	background: transparent;
	border-radius: 0;
	vertical-align: middle;
	padding: 12px 20px 12px 0;
	padding-right: 25px;
	background-image: url('./../../assets/img/_buttons/AButtonText/arrow-gold.svg');
	background-repeat: no-repeat;
	background-position: right 4px center;
	transition: background-position 0.6s $ease-out-quart;

	&:hover {
		background-position: right 0px center;
	}

	&.white {
		color: $fc-white;
		background-image: url('./../../assets/img/_buttons/AButtonText/arrow-white.svg');
	}

	&.dark {
		color: $fc-normal;
		background-image: url('./../../assets/img/_buttons/AButtonText/arrow-dark.svg');
	}

	@include mq(desktop) {
		padding: 15px 20px 15px 0;
	}
}
