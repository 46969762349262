.m-list-huge {
	margin: 0;
	padding: 0;
	list-style: none;

	&.option-with-bullets {
		padding-left: 25px;
	}

	& > li {
		list-style-type: none;
		margin-bottom: between(12px, 18px, $mq-tiny, $mq-desktop);
	}

	@include mq(desktop) {
		& > li {
			margin-bottom: 18px;
		}
	}
}
