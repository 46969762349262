

.a-button-menu {

    &:hover {

    }

    &:active {

    }

    // option-blue
    &.option-white {
        background-color: #fff;
        color: #ccc;
    }
}
