.o-section-image {
	margin-top: 60px;
	margin-bottom: 60px;

	&__wrapper {
		width: 100%;
		max-width: $content-max-width;
		margin-left: auto;
		margin-right: auto;
	}

	&__image {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 40px;
		position: relative;
		transition: opacity 0.6s $ease-out-quart;

		&-source {
			width: 100%;
			height: auto;
		}

		& > .a-label-hint {
			position: absolute;
			top: -20px;
		}

		& > .a-button-overlay {
			position: absolute;
			top: calc(50% - 32px);
			transition: transform 0.6s $ease-out-quart;
		}

		// show hover effect only if the element
		// is a a link with a href attribute
		&[href]:hover {
			opacity: 0.8;

			& > .a-button-overlay {
				transform: translateY(-20px);
			}
		}
	}

	&__content {
		padding-left: 15px;
		padding-right: 15px;
	}

	// options
	&.text-left {
	}

	&.text-right {
	}

	&.background-grey {
	}

	@include mq(mobile) {
		&__content {
			padding-left: 7%;
			padding-right: 7%;
		}
	}

	@include mq(tablet) {
		&__image {
			max-width: 460px;
			margin-left: auto;
			margin-right: auto;
		}

		&__content {
			padding-left: 20px;
			padding-right: 20px;
			max-width: 460px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include mq(desktop) {
		margin-top: calc(100px + 1vw);
		margin-bottom: calc(100px + 1vw);

		&__wrapper {
			display: flex;
			align-content: space-between;
			align-items: flex-start;
		}

		&__image {
			width: 45%;
			margin-bottom: 0;
		}

		&__content {
			padding-top: 25px;
			width: 45%;
		}

		// options
		&.text-left {
			.o-section-image__wrapper {
				flex-direction: row-reverse;
			}
		}

		&.text-right {
		}

		&.background-grey {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: calc(80px + 1vw);
			padding-bottom: calc(80px + 1vw);
			background: $c-background;
		}
	}
}
