.o-section-contact {
	padding-top: 105px;
	padding-bottom: 65px;

	.a-headline-header {
		margin-bottom: 40px;
	}

	.a-headline-small {
		color: $fc-normal;
		font-size: 16px;
		font-weight: $fw-regular;
		text-transform: none;
		margin-bottom: 5px;
	}

	.a-link-main {
		font-family: $ff-first;
		font-size: 32px;
		font-weight: $fw-light;
		line-height: 46px;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.a-text-paragaph {
		margin-top: 10px;
	}
}

.o-section-contact__separator {
	margin: 12px 0 28px;
	border-top: $line-light;
}

@include mq(desktop) {
	.o-section-contact {
		padding-top: 75px;
		padding-bottom: 0;
		height: 100%;
		max-width: 600px;

		.o-section-main__inner-background {
			padding-left: 0;
		}

		.a-headline-header {
			margin-bottom: 70px;
		}

		.a-headline-small {
			color: $fc-normal;
			font-size: 22px;
			font-weight: $fw-regular;
			text-transform: none;
			margin-bottom: 10px;
		}

		.a-link-main {
			font-size: 38px;
			line-height: 46px;
		}
	}

	.o-section-contact__separator {
		margin: 12px 0 28px;
		border-top: $line-light;
	}
}
