.o-section-reserve {
    padding-top: 50px;
    padding-bottom: 65px;

    .a-headline-section {
        margin-bottom: 20px;
    }

    .a-link-main {
        font-size: 38px;
    }

    .a-text-paragraph {
        margin-bottom: 25px;
    }

    .a-headline-small {
        text-transform: none;
        margin-top: 22px;
        margin-bottom: 15px;

        &:first-of-type {
            margin-bottom: 5px;
        }
    }
}

.o-section-reserve__sharelinks {
    display: flex;

    .a-link-round {
        margin-right: 15px;
    }
}

@include mq(tablet) {
    .o-section-reserve {
        .o-section-main__row {
            display: block;
            // justify-content: flex-start;
            padding: 0;

            .o-section-main__text {
                padding-left: 5%;
                padding-right: 5%;
                width: 100%;
            }
        }
    }
}

@include mq(desktop) {
    .o-section-reserve {
        padding-top: 100px;
        padding-bottom: 150px;

        .o-section-reserve__separator {
            display: none;
        }

        .a-link-main {
            font-size: 38px;
        }

        .o-section-main__row {
            display: flex;
            justify-content: center;
            // padding: ;

            .o-section-main__text {
                padding-left: 5%;
                padding-right: 5%;
                width: 100%;
            }
        }
    }
}
