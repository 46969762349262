.o-header-slides {
	scroll-behavior: smooth;
	background: $c-super-dark;
	min-height: 90vh;
	width: 100%;
	position: relative;

	& > .m-box-header {
	}

	&__gallery {
		height: 90vh;
		width: 100%;
		position: relative;

		&-wrapper {
			height: 100%;
		}

		& > .m-pagination-bullets {
			position: absolute;
			width: 100%;
			bottom: 30px;
		}
	}

	&__slide {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		position: relative;

		&.active {
			.o-header-slides__slide-text {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&-text {
			position: absolute;
			text-align: center;
			padding: 0 7%;
			opacity: 0;
			transition: opacity 1.6s $ease-out-cubic, transform 1.6s $ease-out-cubic;
			transition-delay: 0.2s;
			transform: translateY(20px);

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: $ff-second;
				font-style: normal;
				font-weight: $fw-regular;
				line-height: $lh-headline;
				font-size: between(34px, 88px, $mq-tiny, $mq-super);
				color: $fc-white;
			}
		}

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			opacity: 0.55;

			@supports (object-fit: cover) {
				width: 100%;
				height: 100%;
				max-width: auto;
				max-height: auto;
				object-fit: cover;
			}
		}
	}

	@include mq(tablet) {
		&__gallery {
			// height: 70vh;
		}
	}

	@include mq(desktop) {
		& > .m-box-header {
			position: relative;
			width: 75%;
			margin-top: -90px;
			max-width: 820px;
			margin-left: 7%;
			// needed to fix bad swiper-container css!
			z-index: 2;
		}

		&__slide {
			&-text {
				justify-self: flex-start;
				text-align: left;
				padding-left: 7%;
				padding-right: 43%;
			}
		}

		&__gallery {
			height: 98vh;

			& > .m-pagination-bullets {
				text-align: right;
				padding-right: 40px;
				// we have to use !important, as swiper overwrites
				// our styles sadly ...
				bottom: 80px !important;
			}
		}
	}

	@include mq(super) {
		& > .m-box-header {
			margin-left: 8%;
		}

		&__slide {
			&-text {
				padding-left: 8%;
				padding-right: 55%;

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-size: 88px;
				}
			}
		}

		&__gallery {
			& > .m-pagination-bullets {
				padding-right: 120px;
			}
		}
	}
}
