.a-headline-section {
    font-family: $ff-second;
    font-size: 32px;
    line-height: 1.2;
    font-weight: $fw-regular;
    color: $fc-normal;

    &.option-color-white {
        color: #fff;
    }
}

@include mq(desktop) {
    .a-headline-section {
        font-size: 60px;
    }
}
