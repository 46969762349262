//== Sizes
$page-max-width: 1440px;
$content-max-width: 1200px;

//== Fonts
//

//** font families
$ff-neuzeit-grotesk: 'NeuzeitGro', helvetica, arial, sans-serif;
$ff-butler: 'butler', 'Times New Roman', serif;

//** font families for whom
$ff-first: $ff-neuzeit-grotesk;
$ff-second: $ff-butler;

// //** font-sizes
$fs-normal: em(16);
$fs-text: em(16);
$fs-headline: em(18);
$fs-headline-big: em(32);

//** font-weights
$fw-thin: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-x-bold: 800;
$fw-black: 900;

//** font-stretch
$fst-condensed: condensed;
$fst-x-condensed: extra-condensed;

//** line-height
$lh-headline-big: 0.9;
$lh-headline: 1.1;
$lh-normal: 1.2;
$lh-highlight: 1.35;
$lh-text: 1.45;

//== Colors
//
$c-light-grey: #242424;
$c-dark-grey: #181818;
$c-super-dark: #0d0d0d;
$c-error: #d40f4c;
$c-background: #f2f2f2;
$c-background-overlay: #f6f6f6;
$c-gold: #b59659;
$c-gold-dark: #9f834a;

//== Font Colors
$fc-white: #fff;
$fc-grey: #979797;
$fc-normal: #242424;
$fc-gold: #c0a765;

//== Icon Colors
$ic-grey: #808080;

//== Icons
$icon-size-small: 16px;
$icon-size-normal: 24px;
$icon-size-big: 32px;

//== Lines
$line-dark: 1px solid #5a5a5a;
$line-light: 1px solid #dfdfdf;

// == z-index
$z-overlay: 300;
$z-top-bar: 200;
$z-top-bar-sticky: 100;
$z-bottom-bar: 100;
$z-menu: 999999;

//== Ease Functions
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75); // http://easings.net/#linear
$ease: cubic-bezier(0.25, 0.1, 0.25, 1); // http://easings.net/#ease
$ease-in: cubic-bezier(0.42, 0, 1, 1); // http://easings.net/#ease
$ease-out: cubic-bezier(0, 0, 0.58, 1); // http://easings.net/#ease
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1); // http://easings.net/#ease

$ease-in-quad: cubic-bezier(
	0.55,
	0.085,
	0.68,
	0.53
); // http://easings.net/#easeInQuad
$ease-in-cubic: cubic-bezier(
	0.55,
	0.055,
	0.675,
	0.19
); // http://easings.net/#easeInCubic
$ease-in-quart: cubic-bezier(
	0.895,
	0.03,
	0.685,
	0.22
); // http://easings.net/#easeInQuart
$ease-in-quint: cubic-bezier(
	0.755,
	0.05,
	0.855,
	0.06
); // http://easings.net/#easeInQuint
$ease-in-sine: cubic-bezier(
	0.47,
	0,
	0.745,
	0.715
); // http://easings.net/#easeInSine
$ease-in-expo: cubic-bezier(
	0.95,
	0.05,
	0.795,
	0.035
); // http://easings.net/#easeInExpo
$ease-in-circ: cubic-bezier(
	0.6,
	0.04,
	0.98,
	0.335
); // http://easings.net/#easeInCirc
$ease-in-back: cubic-bezier(
	0.6,
	-0.28,
	0.735,
	0.045
); // http://easings.net/#easeInBack

$ease-out-quad: cubic-bezier(
	0.25,
	0.46,
	0.45,
	0.94
); // http://easings.net/#easeOutQuad
$ease-out-cubic: cubic-bezier(
	0.215,
	0.61,
	0.355,
	1
); // http://easings.net/#easeOutCubic
$ease-out-quart: cubic-bezier(
	0.165,
	0.84,
	0.44,
	1
); // http://easings.net/#easeOutQuart
$ease-out-quint: cubic-bezier(
	0.23,
	1,
	0.32,
	1
); // http://easings.net/#easeOutQuint
$ease-out-sine: cubic-bezier(
	0.39,
	0.575,
	0.565,
	1
); // http://easings.net/#easeOutSine
$ease-out-expo: cubic-bezier(
	0.19,
	1,
	0.22,
	1
); // http://easings.net/#easeOutExpo
$ease-out-circ: cubic-bezier(
	0.075,
	0.82,
	0.165,
	1
); // http://easings.net/#easeOutCirc
$ease-out-back: cubic-bezier(
	0.175,
	0.885,
	0.32,
	1.275
); // http://easings.net/#easeOutBack

$ease-in-out-quad: cubic-bezier(
	0.455,
	0.03,
	0.515,
	0.955
); // http://easings.net/#easeInOutQuad
$ease-in-out-cubic: cubic-bezier(
	0.645,
	0.045,
	0.355,
	1
); // http://easings.net/#easeInOutCubic
$ease-in-out-quart: cubic-bezier(
	0.77,
	0,
	0.175,
	1
); // http://easings.net/#easeInOutQuart
$ease-in-out-quint: cubic-bezier(
	0.86,
	0,
	0.07,
	1
); // http://easings.net/#easeInOutQuint
$ease-in-out-sine: cubic-bezier(
	0.445,
	0.05,
	0.55,
	0.95
); // http://easings.net/#easeInOutSine
$ease-in-out-expo: cubic-bezier(
	0,
	0,
	0,
	1
); // http://easings.net/#easeInOutExpo
$ease-in-out-circ: cubic-bezier(
	0.785,
	0.135,
	0.15,
	0.86
); // http://easings.net/#easeInOutCirc
$ease-in-out-back: cubic-bezier(
	0.68,
	-0.55,
	0.265,
	1.55
); // http://easings.net/#easeInOutBack

//== Fades
$fade-hover: 0.35s $ease-out-quart;
