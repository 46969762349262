.w-contact {
}

@include mq(desktop) {
    .w-contact__content {
        display: flex;
        justify-content: center;
        max-width: 1440px;

        padding: 135px 6.74% 123px;
        margin: 0 auto;

        .o-section-main {
            width: 50%;
        }
    }
}
