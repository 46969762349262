.m-view-links {
	max-width: 580px;

	&__image {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: stretch;
		flex-direction: column;

		& > .a-label-hint {
			position: absolute;
			top: -20px;
		}

		&-source {
			flex: 1;
			width: 100%;
			height: auto;
		}
	}

	&__text {
		background-color: #fff;
		padding: 30px 7% 40px;
		position: relative;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $ff-second;
			font-style: normal;
			font-weight: $fw-regular;
			line-height: $lh-headline;
			font-size: between(24px, 29px, $mq-tiny, $mq-desktop);
			margin-bottom: 0.4em;
		}

		& > .a-button-text {
			display: inline-block;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	@include mq(tablet) {
		&__text {
			padding: 44px 30px 46px 60px;
			width: 380px;
			margin-top: -150px;
		}
	}

	@include mq(desktop) {
		&__image {
			margin-left: 60px;
		}

		&__text {
			// padding: 40px 30px 50px 60px;
			// width: 380px;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 29px;
			}

			& > .a-button-text {
				padding-top: 11px;
				padding-bottom: 11px;
			}
		}
	}
}
