.m-pagination-bullets {
	text-align: center;
	width: 100%;

	&.on-dark-background {
		.m-pagination-bullets__bullet {
			background-color: #808080;

			&.active {
				background-color: #fff;
			}
		}
	}

	&.on-white-background {
		.m-pagination-bullets__bullet {
			background-color: #242424;

			&.active {
				background-color: #fff;
			}
		}
	}

	&__bullet {
		display: inline-block;
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background-color: $ic-grey;
		margin: 0 6px;
		opacity: 1;
		cursor: pointer;

		&.active {
			opacity: 1;
			background-color: #fff;
			cursor: none;
		}
	}

	@include mq(desktop) {
		&__bullet {
			height: 12px;
			width: 12px;
			margin: 0 7px;
		}
	}
}
