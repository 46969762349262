.a-button-overlay {
	display: inline-block;
	width: 72px;
	height: 72px;
	vertical-align: middle;
	text-align: center;
	padding: 24px;
	background: rgba(36, 36, 36, 0.8);
	border-radius: 50%;
	color: white;
	border: 0;
	text-decoration: none;

	& > .a-icon-material {
		color: white;
	}

	&:hover {
	}
}
