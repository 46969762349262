.o-bar-menu {
	height: 50px;
	background-color: #242424;
	padding-right: 20px;
	padding-left: 20px;
	z-index: $z-top-bar;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	// options
	// these options are for styling only
	// to display the bar transparent and are
	// meant for showing the bar static on top
	// of all pages
	&.background-transparent-for-black,
	&.background-transparent-for-white {
		background-color: transparent;
		padding-top: 20px;
	}

	// this class has to be added to the bar
	// which should be sticky and fades in
	// after a scroll height x
	&.js-o-bar-menu {
		z-index: $z-top-bar-sticky;
		position: fixed;
		top: 0px;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		opacity: 0;
		transform: translateY(-80px);
		transition: transform 0.5s $ease-out-quad, opacity 0.5s $ease-out-quad;
	}

	// FIX: Dirty fix to prevent seeing the .js-o-bar-menu
	// animations being initially visible after page load ...
	// To fix this, we also apply the basic animations as default
	// in the beginning and remove them with JavaScript
	&.js-hide-initial-animation-on-page-load {
		transition: none;
	}

	// this class is a helper for the sticky
	// bar which fades in after scroll height x
	&.js-sticky {
		opacity: 1;
		transform: translateY(0px);
		pointer-events: all;
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: $content-max-width;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
	}

	.m-control-menu {
		flex: 1;
		&__label {
			display: none;
		}
	}

	&__logo {
		text-align: center;
		flex: 2;
		display: inline-block;
		text-decoration: none;
		transition: opacity $fade-hover;

		&-source {
		}

		&:hover {
			opacity: 0.8;
		}
	}

	&__links {
		flex: 2;
		margin: 0;
		padding: 0;
		display: none;
		align-items: center;
		justify-content: center;

		li {
			padding: 0;
			display: inline-block;
			font-family: $ff-first;
			font-style: normal;
			font-weight: $fw-regular;
			font-size: 19px;
			letter-spacing: 1px;
			text-transform: uppercase;
			padding: 0 16px;
			color: $fc-white;
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	&__buttons {
		display: flex;
		flex: 1;
		justify-content: flex-end;

		.a-button-icon {
		}

		.a-button-action {
			display: none;
		}
	}

	@include mq($until: desktop) {
		&.background-transparent-for-black {
			.m-control-menu__button {
				background-color: transparent;
			}

			.a-button-icon {
				background-color: transparent;
			}
		}
	}

	@include mq(desktop) {
		height: 74px;

		.m-control-menu {
			&__label {
				display: inline-block;
			}
		}

		&__buttons {
			.a-button-icon {
				display: none;
			}

			.a-button-action {
				position: static;
				display: inline-block;
			}
		}
	}

	@include mq(wide) {
		&__links {
			display: flex;
			justify-content: center;

			& + .o-bar-menu__logo {
				// NOTE: Make sure, that the __links are right
				// before the __logo in the html
				display: none;
			}
		}
	}
}
