.o-section-carte {
	height: 500px;

	.o-section-main__inner-background {
		height: 100%;
	}

	.o-section-main__content {
		position: relative;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;

		&.background-carte {
			background-image: url('../../assets/img/event/OSectionCarte/carte.jpg');
		}
	}

	.o-section-main__text {
		max-width: 450px;
		width: 60%;
		position: absolute;
		left: 0;
		bottom: -25px;
		background-color: #fff;
		padding: 40px 40px 0 0;

		.a-link-filled {
			margin-top: 30px;
		}
	}
}

@include mq(wide) {
	.o-section-carte {
		height: 584px;

		.o-section-main__text {
			max-width: 600px;
			padding: 100px 120px 0 0;

			.a-link-filled {
				margin-top: 30px;
			}
		}
	}
}
