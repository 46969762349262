%a-link-main,
.a-link-main {
	color: inherit;
	text-decoration: underline;
	transition: color $fade-hover;

	&:hover {
		color: $c-gold;
	}

	&.white {
		color: #fff;
	}

	&.no-underline {
		text-decoration: none;
	}
}
