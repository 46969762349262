.o-section-widget {
	background-color: #fff;
	padding: 80px 7% 80px;

	&__container {
		max-width: $content-max-width;
		text-decoration: none;
		margin: 0 auto;
		padding: 0 7%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		position: relative;
		text-align: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url('../../assets/img/_sections/OSectionWidget/voucher.jpg');
		height: 360px;
		transition: transform 0.6s $ease-out-cubic;

		&:hover {
			transform: translateY(-10px) scale(1.02);
		}

		.a-icon-main {
			position: absolute;
			top: 0;
			left: 0;
			font-size: 30px;
			padding: 15px;
			background-color: #fff;
			color: $fc-normal;
		}
	}

	&__headline {
		font-family: $ff-second;
		color: $fc-normal;
		font-weight: $fw-regular;
		font-size: between(24px, 58px, $mq-tiny, $mq-wide);
		line-height: $lh-headline;
		color: $fc-white;
		margin-bottom: 0.4em;
	}

	&__text {
		font-family: $ff-first;
		color: $fc-normal;
		font-weight: $fw-regular;
		font-size: between(16px, 24px, $mq-tiny, $mq-wide);
		line-height: $lh-text;
		color: $fc-white;
	}

	@include mq(desktop) {
		padding: 100px 7% 100px;

		&__container {
			padding: 0 16%;
		}
	}

	@include mq(wide) {
		&__headline {
			font-size: 58px;
		}

		&__text {
			font-size: 24px;
		}
	}
}
