.o-section-main {
	&.option-background-white {
		background-color: #fff;
	}

	&.option-background-gold {
		background-color: #b59659;
	}

	&.option-background-dark-grey {
		background-color: $c-dark-grey;
	}

	&.option-background-light-grey {
		background-color: $c-light-grey;
	}

	&.option-background-creme {
		background-color: #f2f2f2;
	}
}

.o-section-main__inner-background {
}

.o-section-main__content {
	&.option-background-light-grey {
		background-color: $c-light-grey;
	}

	&.option-background-dark-grey {
		background-color: $c-dark-grey;
	}
}

.o-section-main__image-container {
	width: 100%;
	overflow: hidden;
	max-height: 320px;
}

.o-section-main__img {
	width: 100%;
}

.o-section-main__text {
	padding-left: 5%;
	padding-right: 5%;

	&.option-background-light-grey {
		background-color: $c-light-grey;

		.a-headline-section,
		.a-text-paragraph {
			color: #fff;
		}
	}

	&.option-background-dark-grey {
		background-color: $c-dark-grey;

		.a-headline-section,
		.a-text-paragraph {
			color: #fff;
		}
	}

	&.background-white {
		background-color: #fff;
	}
}

@include mq(mobile) {
	.o-section-main__image-container {
		max-height: 620px;
	}
}

@include mq(tablet) {
	.o-section-main__image-container {
		max-height: 850px;
	}

	// .o-section-main__row {
	//     padding: 0 5%;
	//     display: flex;
	//     justify-content: space-between;
	//     align-items: center;
	//     flex-grow: 1;
	//
	//     .o-section-main__image-container,
	//     .o-section-main__text {
	//         padding-right: 0;
	//         width: 50%;
	//     }
	// }
}

@include mq(desktop) {
	.o-section-main__inner-background {
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 7%;
		padding-right: 7%;
		background-color: transparent;
	}

	.o-section-main__content {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}

	.o-section-main__row {
		padding: 0 5%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;

		.o-section-main__image-container,
		.o-section-main__text {
			padding-right: 0;
			width: 50%;
		}
	}

	.o-section-main__row {
		padding: 0;
		justify-content: space-around;

		.o-section-main__image-container {
			margin-right: 2.5%;
		}

		.o-section-main__text {
			padding-left: 2.5%;
		}
	}

	.o-section-main__text {
		padding: 0;

		&.highlight-left,
		&.highlight-right {
			background-color: $c-dark-grey;
			width: 80%;
			max-width: 866px;
			position: absolute;
			bottom: -35%;
			right: -6%;
			padding: 60px 50px;

			.a-headline-section {
				color: #fff;
				margin-bottom: 16px;
			}

			.a-text-paragraph {
				color: #fff;
			}
		}

		&.highlight-left {
			left: -6%;
		}

		&.highlight-right {
			right: -6%;
		}
	}
}
