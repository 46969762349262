.a-button-close {
	display: inline-block;
	width: 48px;
	height: 48px;
	vertical-align: middle;
	text-align: center;
	padding: 12px;
	background: rgba(36, 36, 36, 0.3);
	border-radius: 50%;
	color: white;
	border: 0;
	text-decoration: none;
	transition: background $fade-hover;
	outline: none;

	& > .a-icon-material {
		color: white;
	}

	&:hover {
		background: rgba(36, 36, 36, 0.6);
	}

	&:active {
		background: rgba(36, 36, 36, 0.1);
	}
}
