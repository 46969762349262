.w-gallery {
	$nav-space: 70px;
	height: 100vh;
	padding-top: $nav-space;
	background: $c-dark-grey;

	& > .o-content-overlay-gallery {
		height: calc(100vh - #{$nav-space});
		min-height: 0;
	}
}
