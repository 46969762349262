.o-list-cards {
	@include mq(tablet) {
		display: flex;
		flex-direction: column;
		align-items: center;

		.m-card-info {
			margin-bottom: 10px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	@include mq(desktop) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		.m-card-info {
			margin-right: 40px;
			margin-bottom: 0;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}
