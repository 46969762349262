.m-control-slider {
	text-align: center;

	&__prev,
	&__next {
		display: inline-block;
		border: 0;
		background: transparent;
		color: $fc-white;
		width: 44px;
		height: 44px;
		padding: 0;
		transition: transform 0.4s $ease-out-cubic;
		outline: none;

		& > .a-icon-material {
			color: inherit;
		}
	}

	&__prev {
		&:hover {
			transform: translateX(-2px) scale(1);
		}

		&:active {
			transform: translateX(-2px) scale(0.6);
		}
	}

	&__next {
		&:hover {
			transform: translateX(2px) scale(1);
		}

		&:active {
			transform: translateX(2px) scale(0.6);
		}
	}

	&__pagination {
		display: inline-block;
		font-family: $ff-first;
		font-style: normal;
		font-weight: $fw-regular;
		font-size: 19px;
		text-align: center;
		color: $fc-grey;
		width: 100px;
		vertical-align: 6px;

		&-current {
			display: inline-block;
			color: $fc-white;
			padding-right: 4px;
		}
		&-total {
			display: inline-block;

			&:before {
				content: ' / ';
			}
		}
	}
}
