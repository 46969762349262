.o-section-kitchen {
    .o-section-main__image-container {
        margin-bottom: 30px;
    }

    .a-headline-section {
        margin-bottom: 20px;
    }
}

@include mq(desktop) {
    .o-section-kitchen {
        padding-bottom: 40px;

        .o-section-main__image-container {
            margin-bottom: 30px;
        }

        .a-headline-section {
            margin-bottom: 20px;
        }
    }
}
