.a-link-menu-secondary {
	color: #6d6d6d;
	display: inline-block;
	position: relative;
	font-family: $ff-first;
	letter-spacing: 2px;
	font-size: between(14px, 20px, $mq-tiny, $mq-desktop);
	transition: color $fade-hover;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		color: $c-gold;
	}

	& > .a-icon-material {
		position: absolute;
		left: between(-14px, -26px, $mq-tiny, $mq-desktop);
		top: between(4px, 5px, $mq-tiny, $mq-desktop);
		font-size: between(10px, 14px, $mq-tiny, $mq-desktop);
		color: inherit;
	}

	@include mq(desktop) {
		font-size: 20px;

		& > .a-icon-material {
			left: -26px;
			top: 5px;
			font-size: 14px;
		}
	}
}
