.o-bar-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $z-bottom-bar;
	z-index: $z-top-bar-sticky;
	opacity: 0;
	transform: translateY(80px);
	transition: transform 0.6s $ease-out-quad, opacity 0.6s $ease-out-quad;

	& > .a-button-action {
		width: 100%;
	}

	&.js-show {
		opacity: 1;
		transform: translateY(0px);
	}

	@include mq(desktop) {
		display: none;
	}
}
