.m-link-highlight {
	text-decoration: none;
	display: block;
	position: relative;
	border: 1px solid #d9d9d9;
	text-align: left;
	color: $fc-gold;
	font-size: 36px;
	font-family: $ff-second;
	padding: 28px 44px 26px;
	line-height: 1;
	width: 100%;
	transition: color 0.6s $ease-out-quad, background-color 0.6s $ease-out-quad;

	.a-icon-main {
		display: inline-block;
		margin-top: 12px;
		font-size: 33px;
		transition: transform 0.6s $ease-out-quad;
		transform: translateX(0px);
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
	}

	&:hover {
		background-color: $c-gold;
		color: #fff;

		.a-icon-main {
			transform: translateX(20px);
		}
	}

	@include mq(wide) {
		font-size: 42px;

		.a-icon-main {
			font-size: 33px;
		}
	}
}
