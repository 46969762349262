.o-header-voucher {
	.o-header-main__inner-background {
		background: url('../../assets/img/voucher/OHeaderVoucher/voucher.jpg');
		background-repeat: no-repeat;
		// // background-size: cover;
		// background-position: 20% 0%;
	}

	@include mq(tablet) {
		.o-header-main__inner-background {
			// background-repeat: no-repeat;
			// background-size: cover;
			// background-position: 50% 0%;
		}
	}
	@include mq(desktop) {
		.o-header-main__inner-background {
			// background-repeat: no-repeat;
			// background-size: contain;
			// background-position: 0 0;
		}
	}
}
