.o-section-triple {
	padding: 50px 0;

	&__wrapper {
		max-width: $content-max-width;
		margin-left: auto;
		margin-right: auto;
	}

	&__headline {
		font-family: $ff-second;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		color: $fc-normal;
		font-size: between(26px, 38px, $mq-tiny, $mq-desktop);
		text-align: center;
		margin-bottom: 2em;
		text-align: center;
	}

	@include mq(tablet) {
		padding: 80px 60px 60px;
	}

	@include mq(desktop) {
		padding: 100px 30px 60px;

		&__headline {
			font-size: 38px;
		}
	}
}
