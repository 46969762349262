.o-content-overlay-info {
	$text-max-width: 580px;
	scroll-behavior: smooth;

	padding: 50px 20px 120px;
	background: $c-background-overlay;
	min-height: 100vh;
	width: 100%;
	position: relative;

	&__headline {
		font-family: $ff-first;
		font-style: normal;
		color: $fc-grey;
		font-weight: $fw-regular;
		line-height: $lh-headline;
		font-size: between(12px, 19px, $mq-tiny, $mq-desktop);
		text-align: center;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 8px;
	}

	&__logo {
		display: none;
	}

	& > .m-text-section {
		margin-left: auto;
		margin-right: auto;
		max-width: $text-max-width;
	}

	@include mq(mobile) {
		padding: 50px 40px 140px;

		&__headline {
			margin-bottom: 20px;
		}
	}

	@include mq(tablet) {
		padding: 60px 60px 160px;

		&__headline {
			margin-bottom: 60px;
		}
	}

	@include mq(wide) {
		$text-margin-left: 8%;
		padding: 70px 20px 160px;

		&__logo {
			display: inline-block;
			position: absolute;
			left: calc(#{$text-margin-left} + #{$text-max-width} + 90px);
		}

		&__headline {
			font-size: 19px;
			margin-bottom: 90px;
		}

		& > .m-text-section {
			margin-left: $text-margin-left;
			margin-right: auto;
		}
	}

	@include mq(super) {
		$text-margin-left: 20%;

		&__logo {
			left: calc(#{$text-margin-left} + #{$text-max-width} + 120px);
		}

		& > .m-text-section {
			margin-left: $text-margin-left;
		}
	}
}
