.a-link-filled {
	display: inline-block;
	text-decoration: none;
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 36px;
	text-align: center;
	padding: 6px 25px;
	color: #fff;
	text-transform: uppercase;
	background-color: $c-gold;

	&.option-background-color-white {
		background-color: #fff;
		color: $fc-normal;
	}

	&.option-background-color-black {
		background-color: #000;
		color: #fff;
	}

	&.option-background-color-grey {
		background-color: $c-light-grey;
		color: #fff;
	}

	&:hover {
	}
}
