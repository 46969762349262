.o-section-journey {
    padding-top: 45px;
    background-color: #f2f2f2;

    .a-headline-header {
        margin-bottom: 45px;
    }

    .o-section-main__text {
        margin-bottom: 45px;

        .a-text-paragraph {
            margin-bottom: 40px;
        }
    }

    .a-headline-small {
        color: $fc-normal;
        font-size: 16px;
        font-weight: $fw-regular;
        text-transform: none;
        margin-bottom: 5px;
    }
}

.o-section-journey__map-container {
    position: relative;
    padding-bottom: 56.25%; //ratio 16x9
    overflow: hidden;
    width: 100%;
    // min-height: 320px;
    height: auto;
}

.o-section-journey__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// @include mq(mobile) {
//     .o-section-journey__map {
//
//     }
// }
//
// @include mq(tablet) {
//     .o-section-journey__map {
//
//     }
// }

@include mq(desktop) {
    .o-section-journey {
        padding-top: 75px;
        background-color: #f2f2f2;
        max-width: 600px;

        .a-headline-header {
            margin-bottom: 45px;
        }

        .o-section-main__text {
            margin-bottom: 30px;
        }

        .a-text-paragraph {
            margin-bottom: 40px;
        }

        .a-headline-small {
            color: $fc-normal;
            font-size: 22px;
            font-weight: $fw-regular;
            text-transform: none;
            margin-bottom: 10px;
        }

        .o-section-main__inner-background {
            padding-left: 20%;
        }

        .o-section-main__content {
            position: relative;
        }

        .o-section-journey__map-container {
            position: absolute;
            width: 120%;
            height: 50%;
            // right: -20%;
            // bottom: -100%;
        }
    }
}

@include mq(wide) {
    .o-section-journey {
        .o-section-main__inner-background {
            padding-left: 0;
        }

        .o-section-main__inner-background {
            padding-left: 15%;
            padding-right: 15%;
        }

        .o-section-journey__map-container {
            width: 135%;
            height: 60%;
            // min-width: 135%;
        }
    }
}
