.o-section-introduction {
	padding-top: 40px;
	padding-bottom: 80px;

	.o-section-main__text {
		padding-left: 0;
		padding-right: 0;
	}

	.o-slide-image {
		height: 300px;

		&__image {
			// object-fit: cover;
		}
	}

	// .a-headline-section {
	// 	display: none;
	// 	margin-bottom: 20px;
	// }

	// .o-section-main__text {
	// 	margin-bottom: 70px;

	// 	// .a-link-filled {
	// 	//     display: block;
	// 	// }

	// 	&:last-of-type {
	// 		margin: 0;
	// 	}
	// }
}

.o-section-introduction__gallery {
	.m-navigation-gallery {
		display: none;
	}

	.o-slideshow-gallery {
		margin-bottom: 20px;

		&.next,
		&.prev {
			display: none;
		}
	}
}

@include mq(desktop) {
	.o-section-introduction {
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;

		.o-section-main__text {
			margin-left: 23%;
		}

		// .o-section-main__text {
		// 	// .a-link-filled {
		// 	//     display: inline-block;
		// 	// }

		// 	&:first-of-type {
		// 		max-width: 780px;
		// 		padding: 50px 60px;
		// 		width: 70%;
		// 		position: absolute;
		// 		top: -50%;
		// 		margin-bottom: 0;
		// 	}

		// 	.a-headline-section {
		// 		display: none;
		// 	}
		// }
	}

	.o-section-introduction__gallery {
		height: 500px;
		position: relative;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		.o-slideshow-gallery {
			.slick-list,
			.slick-track {
				outline: none !important;
			}

			&.prev {
				margin-right: 20px;
				// position: absolute;
				// top: 0;
				// left: 0;
				margin-top: 5%;
				overflow: hidden;
				height: auto;
				max-height: 400px;
				width: 21%;
				display: inline-block;
			}

			&.current {
				display: inline-block;
				width: 50%;
				height: 80%;
				margin-top: 5%;
				overflow: hidden;
			}
			&.next {
				margin-left: 20px;
				// position: absolute;
				// top: 0;
				// right: 0;
				overflow: hidden;
				height: 45%;
				width: 25%;
				display: inline-block;
			}
		}

		.m-navigation-gallery {
			display: block;
			position: absolute;
			bottom: 115px;
			right: 17%;
		}
	}
}

@include mq(wide) {
	.o-section-introduction {
		// padding-top: 320px;

		// .o-section-main__text {
		// 	&:first-of-type {
		// 		top: -75%;
		// 		.a-text-paragraph {
		// 			font-size: 26px;
		// 			line-height: 1.4;
		// 		}
		// 	}
		// }
	}
}
