.a-headline-header {
    font-family: $ff-first;
    font-size: 18px;
    line-height: 2;
    font-weight: $fw-regular;
    color: $fc-normal;
    text-transform: uppercase;

    &.option-color-white {
        color: #fff;
    }
}

@include mq(desktop) {
    .a-headline-header {
        font-size: 26px;
        line-height: 30px;
    }
}
