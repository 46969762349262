.m-input-text {
    display: block;
    padding-top: 20px;
    position: relative;
    border-bottom: 1px solid #000;
}

.m-input-text__input {
    outline: none;
    border: 0;
    font-size: 16px;
    width: 100%;
    height: 47px;
    padding-left: 35px;

    + .m-input-text__label {
        font-size: 16px;
        font-family: $ff-first;
        opacity: 0;
        position: absolute;
        top: 30px;
        left: 35px;
        transition: top 1s ease, opacity 0.7s ease;
    }

    &:valid + label {
        opacity: 1;
        top: 0px;
    }

    // &:focus + label {
    //     opacity: 1;
    //     top: 0;
    // }
}
