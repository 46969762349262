.a-label-hint {
	display: inline-block;
	text-align: center;
	font-family: $ff-first;
	font-style: normal;
	font-weight: $fw-regular;
	line-height: $lh-normal;
	color: $c-gold;
	font-size: 16px;
	background: #ffffff;
	box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
	border-radius: 40px;
	vertical-align: middle;
	padding: 8px 16px;

	&.negative {
		background: $c-gold;
		color: white;
	}

	@include mq(desktop) {
		padding: 10px 20px;
		font-size: 19px;
	}
}
