.m-box-header {
	padding: 24px 24px 32px;
	font-family: $ff-first;
	font-weight: $fw-regular;
	font-size: between(17px, 27px, $mq-mobile, $mq-super);
	color: $fc-white;
	line-height: $lh-text;
	background-color: $c-dark-grey;
	overflow-wrap: break-word;

	// redactor text-align
	*.text-center {
		text-align: center;
	}

	&.background-dark {
		background-color: $c-dark-grey;
		color: $fc-white;
	}

	&.background-super-dark {
		background-color: $c-super-dark;
		color: $fc-white;
	}

	&.background-grey {
		background-color: #f2f2f2;
		color: $fc-normal;
	}

	&.background-white {
		background-color: #fff;
		color: $fc-normal;
	}

	a {
		color: inherit;
		font-size: inherit;
		text-decoration: underline;
		transition: color $fade-hover;

		&:hover {
			color: $c-gold;
		}
	}

	em,
	i {
		font-style: italic;
	}

	strong,
	b {
		font-weight: $fw-medium;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	& &__headline-big {
		font-family: $ff-second;
		font-weight: $fw-regular;
		font-size: between(32px, 60px, $mq-mobile, $mq-super);
		line-height: $lh-headline;
		color: inherit;
		margin-bottom: 0.5em;
	}

	& &__headline-middle {
		font-size: between(26px, 48px, $mq-mobile, $mq-super);
	}

	& &__headline-small {
		font-size: between(20px, 34px, $mq-mobile, $mq-super);
	}

	p {
		margin-bottom: 0.6em;

		// depending on the element following after a paragraph, margin-bottom can differ.
		// when paragraph is followed by a big headline
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + &__headline-big {
			margin-top: 28px;
		}

		// when paragraph is followed by a middle-sized or small headline
		& + &__headline-middle,
		& + &__headline-small {
			margin-top: 24px;
		}

		// when paragraph is followed by an image (images are wrapped in figures)
		& + figure {
			margin-top: 22px;
		}
	}

	// img styling
	figure {
		margin: 0 0 20px;
		display: block;

		img {
		}

		figcaption {
			font-size: rem(14px);
			color: inherit;
			display: block;
			margin-bottom: 12px;
		}
	}

	hr {
		border-top: $line-light;
		margin: 16px 0;
	}

	@include mq(mobile) {
		padding-left: 6%;
		padding-right: 6%;
	}

	@include mq(tablet) {
		padding-top: 45px;
		padding-bottom: 55px;
	}

	@include mq(desktop) {
		padding: 50px 70px 50px;
	}

	@include mq(wide) {
		padding: 60px 90px 60px;
	}

	@include mq(super) {
		font-size: rem(27px);

		h1,
		h2,
		&__headline-big {
			font-size: rem(60px);
		}

		&__headline-middle {
			font-size: rem(48px);
		}

		&__headline-small {
			font-size: rem(34px);
		}
	}
}

// .m-box-header {
// 	padding: 45px 5% 100px;
// 	background-color: transparent;

// 	.a-headline-section {
// 		margin-bottom: 20px;
// 		color: #fff;
// 	}

// 	.a-text-paragraph {
// 		color: #fff;
// 	}

// 	@include mq(desktop) {
// 		background-color: $c-dark-grey;
// 		width: 80%;
// 		max-width: 866px;
// 		// position: absolute;
// 		// bottom: -35%;
// 		// right: -6%;
// 		position: relative;
// 		top: -150px;
// 		left: 25%;
// 		padding: 60px 50px;

// 		.a-headline-section {
// 			color: #fff;
// 			margin-bottom: 16px;
// 		}
// 	}

// 	@include mq(wide) {
// 		padding: 90px 80px;
// 	}
// }
