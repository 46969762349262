.o-content-overlay-gallery {
	scroll-behavior: smooth;
	background: $c-dark-grey;
	min-height: 100vh;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__gallery {
		height: 75vh;
		width: 100%;

		&-wrapper {
			height: 100%;
		}
	}

	&__slide {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		padding: 0 12px;

		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			transition: 0.2s opacity $ease-in-cubic;

			&.swiper-lazy {
				opacity: 0;
			}

			&.swiper-lazy-loaded {
				opacity: 1;
			}
		}
	}

	& > .m-control-slider {
		top: 10px;
		position: absolute;
	}

	@include mq(tablet) {
		&__gallery {
			height: 70vh;
		}
	}

	@include mq(desktop) {
		&__gallery {
			height: 65vh;
		}

		& > .m-control-slider {
			margin-top: 16px;
			position: static;
		}
	}
}
