.o-section-legal {
	padding: 40px 20px 80px;

	&__wrapper {
		max-width: 760px;
		margin-left: auto;
		margin-right: auto;
	}

	@include mq(mobile) {
		padding: 40px 40px 80px;
	}

	@include mq(tablet) {
		padding: 60px 60px 100px;
	}

	@include mq(desktop) {
		padding: 80px 60px 140px;
	}
}
