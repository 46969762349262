.o-footer-main {
	font-family: $ff-first;
	// background-color: $c-light-grey;
	background-color: #0d0d0d;
	color: #fff;
	text-align: center;

	// FIX
	.a-link-main {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.o-footer-main__content-body {
	padding: 65px 0 70px;

	.o-footer-main__logo {
		margin-bottom: 80px;
	}

	.a-headline-small {
		margin-bottom: 10px;
		color: #fff;
	}

	.m-list-small {
		color: #fff;
		margin-bottom: 80px;
	}
}

.o-footer-main__social-links {
	display: flex;
	justify-content: center;

	.a-link-round {
		margin-right: 8px;

		&:first-of-type {
			border: 0;
			background-color: $c-light-grey;
		}

		&:last-of-type {
			margin-right: 0;
			margin-left: 8px;
		}
	}
}

.o-footer-main__bottom {
	background-color: $c-light-grey;
}

.o-footer-main__content-bottom {
	padding: 70px 0 35px;

	.o-footer-main__language-switch {
		margin-bottom: 38px;
	}

	.o-footer-main__legal-links {
		margin-bottom: 28px;
	}
}

.o-footer-main__language-switch {
	font-size: 16px;

	font-weight: $fw-light;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.o-footer-main__german {
	margin-left: 20px;
	margin-right: 20px;
}

.o-footer-main__legal-links {
	font-size: 12px;
	font-weight: $fw-light;
	line-height: 1.2;

	.a-link-main {
		margin-left: 20px;
	}
}

.o-footer-main__logo {
	width: 136px;
}

@include mq(desktop) {
	.o-footer-main__content-body,
	.o-footer-main__content-bottom {
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}

	.o-footer-main__social {
		text-align: left;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.a-headline-small {
			margin-bottom: 0;
			margin-right: 16px;
		}
	}

	.o-footer-main__social-links {
	}

	.o-footer-main__logo {
		width: 228px;
	}

	.o-footer-main__content-body {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 120px 4% 140px;
		align-items: center;

		.o-footer-main__logo {
			order: 2;
			margin: 0;
		}

		.m-list-small {
			order: 1;
			text-align: left;
			margin: 0;
		}

		.o-footer-main__social {
			order: 3;
		}
	}

	.o-footer-main__content-bottom {
		padding: 50px 4%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		.o-footer-main__language-switch {
			margin-bottom: 0;
			order: 1;
		}

		.o-footer-main__legal-links {
			font-size: 16px;
			margin-bottom: 0;
			order: 3;
		}

		.o-footer-main__copyrights {
			font-size: 16px;
			flex-grow: 2;
			order: 2;
			margin-left: 145px;
		}
	}
}
