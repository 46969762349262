.o-section-events {
	padding: 60px 0;

	&__headline {
		padding: 15px 0;
		margin-bottom: 10px;
		font-family: $ff-first;
		font-style: normal;
		font-weight: $fw-regular;
		line-height: 36px;
		font-size: 19px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $fc-normal;
	}

	&__slider {
		&.swiper-container {
			// margin: 0 30px;
			padding: 30px;
		}
	}

	@include mq(tablet) {
		padding: 60px 0 80px;
	}
}
