.o-section-widget {
	background-color: #fff;
}

.o-section-newsletter__container {
	padding: 40px 0 30px;
	max-width: 1440px;
	margin: 0 auto;
	text-align: center;

	.o-section-widget__headline {
		font-size: 38px;
	}

	&.option-background-particles {
		background-image: url('../../assets/img/_sections/OSectionNewsletter/particles.png');
		background-size: contain;
	}
}

.o-section-newsletter__content {
	background-color: #fff;
	text-align: center;
	display: inline-block;

	.o-section-widget__headline {
		color: #000;
		margin-bottom: 15px;
	}

	.a-text-paragraph {
		margin-bottom: 15px;
	}
}

@include mq(desktop) {
	.o-section-newsletter__container {
		padding: 100px 0;
	}

	.o-section-newsletter__content {
		.a-text-paragraph {
			margin-bottom: 15px;
		}

		.o-section-widget__headline {
			font-size: 38px;
		}

		.m-input-text {
			display: inline-block;
			width: 350px;
		}
	}
}
